var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"iiotDeployStepTwo"}},[_c('the-nerve-table',{class:{ 'wrapper-class': _vm.items.length >= 1 },attrs:{"id":'iiotDeployStepTwoDevicesDeploy',"columns":_vm.nodesColumns,"params":_vm.params,"empty-state":_vm.$t('deployForm.workloadStep2.nodeList.emptyText'),"is-action-menu-enabled":false,"is-row-clickable":true,"is-check-box-column-without-select-all-enabled":true,"store-module":"nodes","custom-fetch-action":"fetch_nodes_for_deploy","check-item-action":"select_node","selected-items-getter":"selectedNodesList"},on:{"params-changed":_vm.changedParams},scopedSlots:_vm._u([{key:"additional-actions",fn:function(){return [_c('v-row',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],staticClass:"node-info"},[_c('v-col',{class:{
            'd-flex justify-end align-center': !_vm.isMarginVisible,
            'd-flex justify-start align-center': _vm.isMarginVisible,
          },attrs:{"cols":"3","lg":"2","md":"2"}},[_c('label',{attrs:{"id":"iiotDeployStepTwoNumberOfNodes","data-cy":"iiotDeployStepTwoNumberOfNodes"}},[_vm._v(" "+_vm._s(_vm.$t('deployForm.workloadStep2.nodes'))+" "+_vm._s(_vm.nodeCount)+" ")])]),_c('v-col',{class:{
            'd-flex justify-center align-center': !_vm.isMarginVisible,
            'd-flex justify-start align-center': _vm.isMarginVisible,
          },attrs:{"cols":"9","lg":"3","md":"4","sm":"4"}},[_c('label',{attrs:{"id":"iiotDeployStepTwoSelectNodes","data-cy":"iiotDeployStepTwoSelectNodes"}},[_vm._v(" "+_vm._s(_vm.$t('deployForm.workloadStep2.selectedNodes'))+" "+_vm._s(_vm.selectedNodesList.length))])])],1)]},proxy:true},{key:"additional-info",fn:function(){return [_c('div',{staticClass:"mt-8"},[_c('v-row',[_c('div',{staticClass:"pl-7"},[_c('v-checkbox',{attrs:{"id":"iiotDeployStepTwoSelectAllCheckbox","data-cy":"iiotDeployStepTwoSelectAllCheckbox","label":_vm.$t('deployForm.workloadStep2.selectAll'),"disabled":!_vm.canAccess('UI_DEPLOY:DEPLOY') || _vm.nodeList.length === 0},on:{"click":_vm.selectRemoveNodes},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}})],1)])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }