<!--
*  TTTech nerve-management-system
*  Copyright(c) 2021. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <div class="step-one">
    <v-row>
      <v-col class="pt-0">
        <div class="text-body1 text-uppercase">
          {{ $t('deployForm.workloadStep1.typeTitle') }}
        </div>
        <div class="text-body2 grey--text text--darken-2">
          {{ $t('deployForm.workloadStep1.typeText') }}
        </div>
        <v-row class="justify-space-between mt-7">
          <v-col v-for="(type, i) in workloadType" :key="type" class="pt-0" lg="3" cols="12">
            <v-btn
              :id="`iiotDeployStepOneCreateWorkload_${i}`"
              :class="[{ 'box-shadow': type === selectedWorkloadType }, `${type}-background`]"
              :disabled="!canAccess('UI_DEPLOY:DEPLOY')"
              class="workloadImg pa-0"
              @click="selectType(type)"
            >
              <v-row>
                <v-col cols="2" lg="3" class="d-flex justify-center align-center">
                  <v-icon v-if="type === 'docker-compose'" id="iiotDeployStepOneWorkloadIcon" size="55">
                    {{ '$vuetify.icons.compose' }}
                  </v-icon>
                  <v-icon v-else id="iiotDeployStepOneWorkloadIcon" size="55">
                    {{ `$vuetify.icons.${type}` }}
                  </v-icon>
                </v-col>
                <v-col cols="9" lg="8" class="d-flex justify-center align-center">
                  <label
                    id="iiotDeployStepOneWorkloadLabel"
                    class="font-weight-bold text-transform-none"
                    :prepend-icon="`$vuetify.icons.${type}`"
                  >
                    {{ $t(`deployForm.workloadStep1.${type}Btn`) }}
                  </label>
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-resize="onResize" class="mt-6 ml-0 mr-0 justify-space-between">
      <v-col
        v-if="selectedWorkloadType !== null"
        lg="6"
        cols="12"
        class="pl-0"
        :class="{ 'pr-3': isMarginVisible, 'pr-0': !isMarginVisible }"
      >
        <div class="text-body1 text-uppercase pt-2">
          {{ $t('deployForm.workloadStep1.workloadTitle') }}
        </div>
        <div class="text-body2 grey--text text--darken-2">
          {{ $t('deployForm.workloadStep1.workloadText') }}
        </div>
        <the-nerve-table
          :id="'iiotDeployStepOneWorkloadDeploy'"
          :columns="workloadColumns"
          :empty-state="$t('deployForm.workloadStep1.workloadList.emptyText')"
          :is-action-menu-enabled="false"
          :is-row-clickable="canAccess('UI_DEPLOY:DEPLOY')"
          :params="params"
          store-module="workloads"
          class="table-content pt-4 pl-0 ml-0"
          @params-changed="updateParams"
          @row-clicked="(params) => showVersion(params.item)"
        />
      </v-col>
      <v-col
        v-if="selectedWorkload"
        id="iiotDeployStepOneWorkloadVersions"
        lg="6"
        cols="12"
        class="pr-0"
        :class="{ 'pl-3': isMarginVisible, 'pl-0': !isMarginVisible }"
      >
        <div class="text-body1 text-uppercase pt-2">
          {{ $t('deployForm.workloadStep1.workloadVersion') }}
        </div>
        <div class="text-body2 grey--text text--darken-2">
          {{ $t('deployForm.workloadStep1.selectWorkload') }}
        </div>
        <the-nerve-table
          :id="'iiotDeployStepOneVersionDeploy'"
          :columns="versionColumns"
          :empty-state="$t('deployForm.workloadStep1.versionList.emptyText')"
          :is-action-menu-enabled="false"
          :is-row-clickable="canAccess('UI_DEPLOY:DEPLOY')"
          :params="parameters"
          :disable-pagination="true"
          :hide-footer="true"
          class="table-content pt-4"
          store-module="workloads"
          custom-list-getter="getVersions"
          custom-count-getter="versionCount"
          :custom-fetch-action="selectedWorkloadType !== 'docker-compose' ? 'initVersion' : 'initComposeVersion'"
          :custom-search-action="selectedWorkloadType !== 'docker-compose' ? 'fetchVersion' : 'fetchComposeVersion'"
          @row-clicked="(params) => selectVersion(params.item)"
          @params-changed="(parameters) => changedParams(parameters)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TheNerveTable from 'nerve-ui-components/components/TheNerveTable.vue';

export default {
  components: {
    TheNerveTable,
  },
  data() {
    return {
      workloadType: ['docker', 'vm', 'codesys', 'docker-compose'],
      params: {
        selectedType: '',
        disabled: false,
        sortBy: [],
        sortDesc: [],
      },
      parameters: {
        limit: 10,
        page: 1,
      },
      selectedWorkloadType: null,
      versionFilter: { filterBy: { type: null } },
      selectedWorkload: null,
      deployInformation: {},
      deployName: new Date().toLocaleString().replace(/\s/g, ''),
      workloadName: '',
      versionName: '',
      selectedVersion: {},
      isMarginVisible: true,
    };
  },
  computed: {
    workloadColumns() {
      return [
        {
          text: this.$t('deployForm.workloadStep1.workloadList.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('deployForm.workloadStep1.createdColumn'),
          value: 'created',
          sortable: false,
        },
        {
          text: this.$t('deployForm.workloadStep1.workloadList.description'),
          value: 'description',
          sortable: false,
        },
      ];
    },
    versionColumns() {
      return [
        {
          text: this.$t('deployForm.workloadStep1.versionList.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: this.$t('deployForm.workloadStep1.versionList.releaseName'),
          value: 'releaseName',
          sortable: false,
        },
        {
          text: this.$t('deployForm.workloadStep1.createdColumn'),
          value: 'created',
          sortable: false,
        },
      ];
    },
  },
  methods: {
    emitData() {
      return {
        deployInfo: this.deployInformation,
        generalInfo: {
          wlName: this.workloadName,
          versionName: this.versionName,
          selectedWorkloadType: this.selectedWorkloadType,
        },
        selectedVersion: this.selectedVersion,
      };
    },
    updateParams(parameters) {
      this.params = {
        ...parameters,
        selectedType: this.params.selectedType,
        disabled: this.params.disabled,
        sortBy: this.params.sortBy,
        sortDesc: this.params.sortDesc,
      };
    },
    changedParams(parameters) {
      this.parameters = parameters;
    },
    async selectType(type) {
      this.selectedWorkloadType = type;
      this.selectedWorkload = null;
      this.params.selectedType = type;
      await this.$store.dispatch('workloads/fetch', this.params);
    },
    async showVersion(wl) {
      try {
        if (this.selectedWorkloadType === 'docker-compose') {
          await this.$store.dispatch('workloads/get_compose_workload_by_id', wl._id);
          this.selectedWorkload = this.$store.getters['workloads/getComposeWorkload'];
          await this.$store.dispatch('workloads/initComposeVersion');
        } else {
          await this.$store.dispatch('workloads/get_workload_by_id', wl._id);
          this.selectedWorkload = this.$store.getters['workloads/getWorkload'];
          await this.$store.dispatch('workloads/initVersion');
        }
        this.deployInformation.versionId = '';
        this.$emit('selected-version', this.emitData());
        // eslint-disable-next-line no-underscore-dangle
        this.deployInformation.workloadId = this.selectedWorkload._id;
        this.workloadName = this.selectedWorkload.name;
      } catch (e) {
        this.$log.debug(e);
      }
    },
    selectVersion(version) {
      this.selectedVersion = version;
      this.deployInformation.versionId = version._id;
      this.versionName = version.name;
      this.$emit('selected-version', this.emitData());
    },
    onResize() {
      this.isMarginVisible = window.innerWidth > 1264;
    },
  },
};
</script>

<style lang="scss">
.step-one {
  #iiotDeployStepOneWorkloadDeploy {
    .v-data-table__wrapper {
      max-height: 265px !important;
      overflow-y: auto;
    }
    .v-data-footer {
      margin-top: 10px;
    }
  }
  .v-input__slot {
    width: 300px;
  }
  .v-data-footer__select {
    .v-input__slot {
      width: 47px;
    }
  }
}

#iiotDeployStepOneVersionDeploy {
  .v-data-table__wrapper {
    max-height: 265px !important;
  }
}
</style>
